import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { tap } from "rxjs/operators";
import { UserService } from "@app/_services/user.service";
import { Router, RouterLink } from "@angular/router";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmedValidator } from "../confirmed.validator";
import { ConfirmationDialog } from "../confirm-box/confirmation-dialog.component";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.less"],
})
export class UsersComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  pageEvent: any;
  rolesData: any;
  postData: {};
  userForm: FormGroup;
  message = "";
  message_class = "";
  filter = "";
  loading = false;
  submitted = false;

  // displayedColumns: string[] = ['sr','first_name', 'last_name', 'email','role_name','download_quota','is_pay_status','action'];
  displayedColumns: string[] = [
    "sr",
    "first_name",
    "email",
    "role_name",
    "download_quota1",
    "download_quota",
    "action",
    "update_status",
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // MatPaginator Inputs
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userService: UserService
  ) {
    this.userService.rolesData.subscribe((x) => {
      this.rolesData = x.role_permission;
    });
  }

  ngOnInit() {
    this.loadData();
  }

  checkPermission(jsObjects, key) {
    if (jsObjects != undefined) {
      let data = jsObjects.filter((obj) => {
        return obj.permission_key === key && obj.permission_value === 1;
      });
      if (data.length) {
        return true;
      } else {
        return false;
      }
    }
  }

  hideAction(val1, val2) {
    if (
      this.checkPermission(this.rolesData, val1) ||
      this.checkPermission(this.rolesData, val2)
    ) {
      return false;
    } else {
      return true;
    }
  }

  openSnackBar(message) {
    this._snackBar.open(message, "", {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  navigateTo(val) {
    this.router.navigate([val]);
  }

  loadData() {
    this.message = "";
    const data = {
      filter: this.filter,
      sortOrder: this.sort != undefined ? this.sort.direction : "",
      sortField:
        this.sort != undefined
          ? this.sort.active != undefined
            ? this.sort.active
            : ""
          : "",
      page: this.paginator.pageIndex,
      limit:
        this.paginator.pageSize != undefined ? this.paginator.pageSize : 10,
    };
    console.log(data);
    this.userService
      .getAll(data)
      .then((result: any) => {
        if (result.status === true) {
          this.dataSource = new MatTableDataSource(result.data);
          this.length = result.count;
        } else {
          this.dataSource = new MatTableDataSource();
          this.message = result.message;
        }
      })
      .catch((error) => {
        this.dataSource = new MatTableDataSource();
        this.message_class = "danger";
        this.message = "Network error";
        // console.log(error)
      });
  }

  applyFilter() {
    this.filter = (document.getElementById("filter") as HTMLInputElement).value;
    // if(this.filter !=''){
    this.paginator.pageIndex = 0;
    this.loadData();
    // }
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadData())).subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadData();
        })
      )
      .subscribe();
  }

  editRow(id) {
    this.userService
      .getUser(id, "editUser")
      .then((result: any) => {
        if (result.status === true) {
          this.message_class = "success";

          const dialogRef = this.dialog.open(AddUserModal, {
            width: "750px",
            data: result.data,
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log("The dialog was closed", result);
            this.loadData();
          });
        } else {
          this.message_class = "danger";
        }
        console.log(result);
        // this.message 		= result.message;
      })
      .catch((error) => {
        this.message_class = "danger";
        this.message = "Network error";
        console.log(error);
      });
  }
  deleteRow(id) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: "Are you sure you want to delete this?",
        buttonText: {
          ok: "Yes",
          cancel: "No",
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.userService
          .deleteUser(id)
          .then((result: any) => {
            if (result.status === true) {
              this.message_class = "success";
              this.loadData();
            } else {
              this.message_class = "danger";
            }
            this.openSnackBar(result.message);
          })
          .catch((error) => {
            this.openSnackBar("Network error");
          });
      }
    });
  }

  updatePaymentStatus(id) {
    console.log(id);
    // return;
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: "Are you sure you want to update payment status?",
        buttonText: {
          ok: "Yes",
          cancel: "No",
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        console.log(id);
        // return;
        this.userService
          .updatePaymentStatus(id)
          .then((result: any) => {
            if (result.status === true) {
              this.message_class = "success";
              this.loadData();
            } else {
              this.message_class = "danger";
            }
            this.openSnackBar(result.message);
          })
          .catch((error) => {
            this.openSnackBar("Network error");
          });
      }
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddUserModal, {
      width: "750px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      this.loadData();
    });
  }
}

@Component({
  selector: "add-user",
  templateUrl: "add-user.html",
})
export class AddUserModal {
  rolesData: any;
  roles: any;
  postData: {};
  userForm: FormGroup;
  message = "";
  message_class = "";
  filter = "";
  type = "";
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddUserModal>,
    private userService: UserService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const post_data = { id: "sddsds" };
    this.userService
      .getRoles(post_data)
      .then((result: any) => {
        if (result.status === true) {
          this.roles = result.data;
        }
      })
      .catch((error) => {
        this.message_class = "danger";
        this.message = error;
      });

    this.userService.rolesData.subscribe((x) => {
      this.rolesData = x.role_permission;
    });
  }

  onNoClick(): void {
    this.dialogRef.close("submit");
  }

  ngOnInit() {
    console.log(this.dialogRef);

    const userData = this.dialogRef.componentInstance.data;
    if (userData != null) {
      this.userForm = this.formBuilder.group(
        {
          first_name: ["", Validators.required],
          last_name: [""],
          email: ["", [Validators.required, Validators.email]],
          phone: [""],
          password: [""],
          confirm_password: [""],
          download_quota: [""],
          role_id: ["", [Validators.required]],
        },
        {
          validator: ConfirmedValidator("password", "confirm_password"),
        }
      );

      this.userForm.controls["first_name"].setValue(userData.first_name);
      this.userForm.controls["last_name"].setValue(userData.last_name);
      this.userForm.controls["email"].setValue(userData.email);
      this.userForm.controls["phone"].setValue(userData.phone);
      this.userForm.controls["role_id"].setValue(userData.role_id);
      this.userForm.controls["download_quota"].setValue(
        userData.download_quota
      );
      this.userForm.controls["email"].disable({ onlySelf: true });
      this.type = "Update";
    } else {
      this.type = "Add";
      this.userForm = this.formBuilder.group(
        {
          first_name: ["", Validators.required],
          last_name: [""],
          email: ["", [Validators.required, Validators.email]],
          phone: [""],
          password: ["", [Validators.required, Validators.minLength(6)]],
          confirm_password: ["", [Validators.required]],
          download_quota: [""],
          role_id: ["", [Validators.required]],
        },
        {
          validator: ConfirmedValidator("password", "confirm_password"),
        }
      );
    }
  }

  get f() {
    return this.userForm.controls;
  }

  onSubmit() {
    const userData = this.dialogRef.componentInstance.data;
    if (userData != null) {
      this.edit(userData.id);
    } else {
      this.add();
    }
  }
  edit(id) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }

    this.postData = {
      role_id: this.f.role_id.value,
      first_name: this.f.first_name.value,
      last_name: this.f.last_name.value,
      phone: this.f.phone.value,
      password: this.f.password.value,
      download_quota: this.f.download_quota.value,
    };
    this.loading = true;

    this.userService
      .editUser(id, this.postData)
      .then((result: any) => {
        if (result.status === true) {
          this.message_class = "success";
          this.onNoClick();
          return false;
        } else {
          this.message_class = "danger";
        }
        this.message = result.message;
      })
      .catch((error) => {
        this.message_class = "danger";
        this.message = error;
      });
  }

  add() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }

    this.postData = {
      role_id: this.f.role_id.value,
      first_name: this.f.first_name.value,
      last_name: this.f.last_name.value,
      email: this.f.email.value,
      phone: this.f.phone.value,
      password: this.f.password.value,
    };
    this.loading = true;
    this.userService
      .AddUser(this.postData)
      .then((result: any) => {
        if (result.status === true) {
          this.message_class = "success";
          this.onNoClick();
          return false;
        } else {
          this.message_class = "danger";
        }
        this.message = result.message;
      })
      .catch((error) => {
        this.message_class = "danger";
        this.message = error;
      });
  }
}
